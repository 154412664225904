<template>
  <div id="scraping-schedule-page">
    <b-card no-body class="h-100">
      <b-card-header class="pb-1">
        <b-card-title>
          <i class="bi bi-calendar3"></i>
          <span style="margin-left: 5px"> Peak Time </span>
        </b-card-title>
        <select
          v-model="vm_selected"
          class="form-control"
          @change="getPeaktime"
          style="width: 10rem"
        >
          <option v-for="option in server_options" :value="option._id">
            {{ option.name }}
          </option>
        </select>
      </b-card-header>
      <div>
        <loader-component v-if="is_peaktime_loading" />
        <div v-else>
          <heatmap-chart-component :data="peaktime_data" />
        </div>
      </div>
    </b-card>
    <b-card no-body class="h-100">
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-calendar3"></i>
          <span style="margin-left: 5px"> Jadwal Scraping </span>
        </b-card-title>
        <div class="d-flex">
          <b-button variant="outline-success" @click="exportSchedule">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- hour filter -->
          <form @submit.prevent="changeHour">
            <b-input-group class="input-group-merge ml-1" style="width: 8rem">
              <b-input-group-prepend is-text style="height: 2.7rem">
                <i class="bi bi-hourglass"></i>
              </b-input-group-prepend>
              <b-form-input
                placeholder="Jam"
                v-model="hour"
                type="number"
                min="0"
                max="23"
              />
            </b-input-group>
          </form>
          <!-- minutes filter -->
          <form @submit.prevent="changeMinute">
            <b-input-group class="input-group-merge ml-1" style="width: 8rem">
              <b-input-group-prepend is-text style="height: 2.7rem">
                <feather-icon icon="ClockIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Menit"
                v-model="minute"
                type="number"
                min="0"
                max="60"
              />
            </b-input-group>
          </form>
          <!-- keyword filter -->
          <form @submit.prevent="changeSearch">
            <b-input-group class="input-group-merge ml-1" style="width: 20rem">
              <b-input-group-prepend is-text style="height: 2.7rem">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Masukkan kata kunci..."
                v-model="name"
              />
            </b-input-group>
          </form>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component v-else :data="table_data" />
    </b-card>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import heatMap from "@/views/components/heatMap.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BButton,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTimepicker,
} from "bootstrap-vue";

export default {
  name: "ScrapingSchedule",
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTimepicker,
    "table-component": dataTable,
    "heatmap-chart-component": heatMap,
    "loader-component": loader,
  },
  data() {
    return {
      is_peaktime_loading: true,
      is_loading: true,
      hour: "",
      minute: "",
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      name: "",
      peaktime_data: [],
      server_options: [],
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "auto" },
          },
          {
            key: "minute",
            label: "menit",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "hour",
            label: "jam",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "cron",
            label: "Cron",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "tag",
            label: "Tags",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "server",
            label: "server",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
        ],
        items: null,
      },
      CancelToken: null,
      vm_selected: "VM1",
    };
  },
  created() {
    this.getServerOptions();
    this.getData();
    this.getPeaktime();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.getData();
    },
    name() {
      if (this.name.length == 0 || this.name.length > 2) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
    hour() {
      if (!this.hour) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
    minute() {
      if (!this.minute) {
        this.updateQuery("page", 1);
        this.getData();
      }
    },
  },
  methods: {
    getServerOptions() {
      useJwt.axiosIns.get("crontab/server").then((res) => {
        this.server_options = res.data || [];
        this.server_options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
    },
    getData() {
      this.is_loading = true;
      if (this.CancelToken) {
        this.CancelToken.cancel();
        this.is_loading = true;
      }
      this.CancelToken = axios.CancelToken.source();
      this.is_loading = true;
      const params = {
        ...(this.name ? { name: this.name } : {}),
        ...(this.hour ? { jam: Number(this.hour) } : {}),
        ...(this.minute ? { menit: Number(this.minute) } : {}),
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.CancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.crontab.length > 0) {
            this.table_data.items = res.data.crontab;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch(() => {
          this.is_loading = false;
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    getPeaktime() {
      this.is_peaktime_loading = true;
      const params = {
        server: this.vm_selected,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/peak-time?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.CancelToken.token })
        .then((res) => {
          if (res.status == 200) {
            this.assemblePeakTime(res.data);
          }
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          this.is_peaktime_loading = false;
        });
    },
    assemblePeakTime(data) {
      const temp_data = {};
      data.forEach((item) => {
        const [jam, menit, total] = item;
        let range_menit = 0;
        if (menit < 6) {
          range_menit = 0;
        } else {
          range_menit = Math.floor((menit - 1) / 5) * 5 + 1;
        }

        const name = `${range_menit}-${
          range_menit == 0 ? range_menit + 5 : range_menit + 4
        }`;
        if (!temp_data[name]) {
          temp_data[name] = {
            name: name,
            data: [],
          };
        }
        const existing_data = temp_data[name].data.find(
          (d) => d.x === jam.toString().padStart(2, "0")
        );
        if (existing_data) {
          existing_data.y += total;
        } else {
          temp_data[name].data.push({
            x: jam.toString().padStart(2, "0"),
            y: total,
          });
        }
      });
      this.peaktime_data = Object.values(temp_data);
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    changeSearch() {
      this.updateQuery("page", 1);
      this.getData();
    },
    changeHour() {
      this.updateQuery("page", 1);
      this.getData();
    },
    changeMinute() {
      this.updateQuery("page", 1);
      this.getData();
    },
    exportSchedule() {
      const params = {
        ...(this.name ? { name: this.name } : {}),
        ...(this.hour ? { jam: Number(this.hour) } : {}),
        ...(this.minute ? { menit: Number(this.minute) } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/export?" + query;
      window.open(api);
    },
  },
};
</script>
